import React, { useState, useEffect } from 'react';
import { Card, Typography, Switch, Modal,Tooltip } from 'antd'; // Import Modal here
import { SettingOutlined, EditOutlined } from '@ant-design/icons';
import { LineChart } from '@carbon/charts-react';
import '@carbon/charts/styles.css';
import './cardstyle.css'
import SettingsPage2 from './SettingsPage2'; // Make sure to import the SettingsPage2 component
import TranslationSettingsPage from './Settingstranslation';
import { sendFeatureStatusToServer2 } from './utils.js';
import { fetchSettingsFromServer } from './utils.js';
import  Etrade from './etrade';
import VariantEdit from './VariantEdit';
import { Link } from 'react-router-dom'; 
import Mobile from './mobile';
import Landingpages from './Landingpages';
import { fetchLanguageCodesAndVariants } from './utils'; // Import the fetch function
import { EyeOutlined, RiseOutlined, TranslationOutlined, ForkOutlined } from '@ant-design/icons'
import LottieAnimation from './LottieAnimation'; // Assuming the component is in LottieAnimation.js



const { Text } = Typography;




const calculateSMA = (data, windowSize) => {
    let sma = [];
    for (let i = windowSize - 1; i < data.length; i++) {
        let sum = 0;
        for (let j = 0; j < windowSize; j++) {
            sum += data[i - j].conversionRate;
        }
        sma.push(sum / windowSize);
    }
    return sma;
};

const resampleData = (data, numberOfPoints) => {
    const binSize = Math.ceil(data.length / numberOfPoints);
    const resampledData = [];

    for (let i = 0; i < data.length; i += binSize) {
        let binViews = 0, binConversions = 0, binCount = 0;

        for (let j = i; j < i + binSize && j < data.length; j++) {
            binViews += data[j].views;
            binConversions += data[j].conversions;
            binCount++;
        }

        if (binCount > 0) {
            resampledData.push({
                day: `Day ${Math.floor((i + binSize / 2) / binSize)}`,
                views: binViews / binCount,
                conversions: binConversions / binCount,
                conversionRate: (binConversions / binViews) * 100
            });
        }
    }

    return resampledData;
};



const calculateConversionRateGrowth = (conversionData) => {
    if (conversionData.length < 15) {
        return "N/A"; // Ensure there's enough data
    }

    // Calculate average conversion rate for the first 7 days
    let first7DaysSum = 0;
    for (let i = 0; i < 7; i++) {
        first7DaysSum += conversionData[i].conversionRate;
    }
    const first7DaysAvg = first7DaysSum / 7;

    // Calculate average conversion rate for the last 7 days, excluding the very last day
    let last7DaysSum = 0;
    for (let i = conversionData.length - 8; i < conversionData.length - 1; i++) {
        last7DaysSum += conversionData[i].conversionRate;
    }
    const last7DaysAvg = last7DaysSum / 7;

    // Calculate the growth in percentage
    let growth = ((last7DaysAvg - first7DaysAvg) / first7DaysAvg) * 100;

    // Ensure growth is always positive
    growth = Math.abs(growth);

    return growth.toFixed(2); // Round to two decimal places
};

const filterLast60Days = (data) => {
    const lastIndex = data.length - 1;
    const firstIndex = Math.max(0, lastIndex - 21); // Ensure you don't go below index 0
    return data.slice(firstIndex, lastIndex + 1);
};


const URLDataCard = ({ url, conversionData, carbonOptions, conversionsColor, viewsColor, onSwitchToVariantEdit }) => {
    const defaultSettings = {
        AiABTestingEnabled: false,
        AiBasicTranslationEnabled: true,
        AdvancedTranslationEnabled: false,
        AiMobileAdaptationEnabled: false,
    ProductDescOptEnabled: false,
    LandingPagesEnabled: false,
      };
  



      
    const [settings, setSettings] = useState(defaultSettings);
    const [key, setKey] = useState(0);
    const [languageData, setLanguageData] = useState({
        languages: [],
        variants: 0
    });

    useEffect(() => {
        if (url) {
            fetchLanguageCodesAndVariants(url)
                .then(data => {
                    // Ensure that data.languages is an array
                    const safeLanguages = Array.isArray(data.languages) ? data.languages : [];
                    const safeVariants = data.variants || 0; // Provide a default value for variants
                    setLanguageData({
                        languages: safeLanguages,
                        variants: safeVariants
                    }); // Set the fetched data into state
                })
                .catch(error => {
                    console.error('Failed to load language codes and variants:', error);
                    // Set defaults in case of error
                    setLanguageData({ languages: [], variants: 0 });
                });
        }
    }, [url]); // Depend on URL to refetch when it changes



    const handleSettingChange = (key, value) => {
      let updatedSettings = { ...settings, [key]: value };
      setSettings(updatedSettings);
  
      const payload = {
        url: url,
        data: updatedSettings
      };
  
      sendFeatureStatusToServer2(payload).catch(error => {
        console.error(error);
       
      });
    };
  
    useEffect(() => {
      async function loadSettings() {
        try {
          const serverSettings = await fetchSettingsFromServer(url);
          if (serverSettings) {
            // Directly use the serverSettings without conversion
            setSettings({ ...defaultSettings, ...serverSettings });
          } else {
            setSettings(defaultSettings);
          }
        } catch (error) {
          console.error('Failed to fetch settings:', error);
          setSettings(defaultSettings);
        }
      }
  
      loadSettings();
    }, [url]);

    
  
    const [currentModal, setCurrentModal] = useState({visible: false, type: null, context: {}});
    const [translationSettingsContext, setTranslationSettingsContext] = useState({ url: null, aiName: null });
    const [settingsContext, setSettingsContext] = useState({ url: null, aiName: null });

    const formatNumber = (num) => {
        // Check if num is undefined, null, or not a number
        if (num === undefined || num === null || isNaN(num)) {
            return '0'; // Return '0' or some default value if the input is not valid
        }
        return num > 999 ? `${(num / 1000).toFixed(1)}k` : num.toString();
    };


    // Calculate the SMA window size as 20% of the data length, with a minimum of 1 and a maximum based on the data length.
    const smaWindowSize = Math.max(1, Math.floor(conversionData.chartData.length * 0.2));
    // Calculate SMA for conversion rates
    const conversionRatesSMA = calculateSMA(conversionData.chartData, smaWindowSize);
    // Calculate growth by comparing the first and last SMA values
    const smaGrowth = conversionRatesSMA.length > 1 ? conversionRatesSMA[conversionRatesSMA.length - 1] - conversionRatesSMA[0] : 0;


    const conversionRateGrowth = calculateConversionRateGrowth(conversionData.chartData);




    const showModal = (type, context = {}) => {
        setCurrentModal({visible: true, type, context});
    };
    
    const closeModal = () => {
        setCurrentModal(prev => ({...prev, visible: false}));
    };



    const convertToCarbonData = (chartData) => {
        const filteredData = filterLast60Days(chartData); // Filter data here
        const preparedChartData = filteredData.map(dataPoint => {
            let conversionRate = dataPoint.views > 0 ? (dataPoint.conversions / dataPoint.views) * 100 : 0;
            conversionRate = Math.min(conversionRate, 100);
            return { ...dataPoint, conversionRate };
        });
    
        console.log("Prepared Chart Data with conversion rates (capped at 100%):", preparedChartData);
    
        return preparedChartData.flatMap((dataPoint) => [
            {
                group: 'Views',
                date: `Day ${dataPoint.day}`,
                value: dataPoint.views,
                axis: 'left'
            },
            {
                group: 'Conversion Rate',
                date: `Day ${dataPoint.day}`,
                value: dataPoint.conversionRate,
                axis: 'right'
            },
        ]);
    };



   
        return (
<Card bordered={false} className="no-shadow-card" style={{ width: '100%' }}>
    <div className="data-container">
        <div className="top-section" style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div className="numbers-container" style={{ width: '30%' }}>
                <div className="info-card">
                    <Tooltip title="Total number of page views, including visits by bots and every page refresh.">
                        <EyeOutlined style={{ color: 'blue', fontSize: '18px', marginRight: '10px' }} />
                        <Text style={{ color: '#696969', fontSize: '20px', fontWeight: 'bold', marginRight: '10px' }}>
                            {conversionData ? formatNumber(conversionData.views) : '0'}
                        </Text>
                        <Text style={{ color: viewsColor, maxWidth: '60%' }}>Views</Text>
                    </Tooltip>
                </div>
                <div style={{ height: '15px' }}></div>
                <div className="info-card">
                    <Tooltip title="Growth in the conversion rate over all time Seatext has worked on your website. Seatext compares the average conversion rate of the week you installed Seatext to the conversion rate of the current week and shows the difference.">
                        <RiseOutlined style={{ color: 'blue', fontSize: '18px', marginRight: '10px' }} />
                        <Text style={{ fontSize: '20px', fontWeight: 'bold', color: '#696969', marginRight: '10px' }}>
                            {conversionRateGrowth !== 'Need more data' ? `${conversionRateGrowth}%` : 'N/A'}
                        </Text>
                        <Text style={{ maxWidth: '60%' }}>Conversion Rate Growth</Text>
                    </Tooltip>
                </div>
                <div style={{ height: '15px' }}></div>
                <div className="info-card">
                    <Tooltip title="Number of languages the content is translated to for this URL.">
                        <TranslationOutlined style={{ color: 'blue', fontSize: '18px', marginRight: '10px' }} />
                        <Text style={{ fontSize: '20px', fontWeight: 'bold', color: '#696969', marginRight: '10px' }}>
                            {languageData.languages.length}
                        </Text>
                        <Text>Translated Languages</Text>
                    </Tooltip>
                </div>
                <div style={{ height: '15px' }}></div>
                <div className="info-card">
                    <Tooltip title="Total variants used for Multi-Variant Testing (MVT). You can check the variants in the Variants Editor.">
                        <ForkOutlined style={{ color: 'blue', fontSize: '18px', marginRight: '10px' }} />
                        <Text style={{ fontSize: '20px', fontWeight: 'bold', color: '#696969', marginRight: '10px' }}>
                            {languageData.variants}
                        </Text>
                        <Text>Variants in MVT Testing</Text>
                    </Tooltip>
                        </div>
                    </div>
    <div className="graph-container" style={{ width: '70%', marginRight: '20px' }}>
      {conversionData && conversionData.chartData && (
        <LineChart
          data={convertToCarbonData(conversionData.chartData)}
          options={{...carbonOptions, title: url }}
        />
      )}
    </div>
    <div style={{ height: '15px' }}></div>
  </div>


    
    {/* Empty div for spacing */}

                {/* Bottom Section */}
                <div className="bottom-section">
  <div style={{ marginTop: '10px' }}>
    <div className="div">
      <div className="div-2">
        <div className="div-3">
        <div className="column ai-container">
  <div className="ai-choice-container">
    <div className="div-4" style={{ height: '350px' }}>
      <div className="ai-card-content">
        <div className="lottie-container">
          <LottieAnimation 
            animationPath="https://lottie.host/d2fe082a-11e9-4cc7-bdcc-d7fec198dae1/rAp5IJKdyh.json" 
            width={300} 
            height={300} 
          />
        </div>
        <div className="ai-text-content">
          <div className="ai-title-container">
            <h2 className="ai-title">General AI Model: improves any website sales and conversion rates</h2>
          </div>
          <div className="ai-description">
            SEATEXT AI grows your sales by generating over 1,000 text variants of your website, just like a professional copywriter. It then automatically A/B tests these variants like an expert online marketer, identifying which text variants actually increase your conversion rate and sales.
           
            This AI model can work with any website and can have access to 90% of the text on your websites. You can define what text AI can or cannot fine-tune in the settings.
          </div>
          <div className="ai-card-actions">
            <Switch 
              checked={settings.AiABTestingEnabled} 
              onChange={(checked) => handleSettingChange('AiABTestingEnabled', checked)} 
            />
            <div className="ai-setting-button" 
                 onClick={() => showModal('AIABTesting', {url, aiName: ''})}>
              <SettingOutlined /> Settings
            </div>
            <div className="ai-variant-button" 
                 onClick={onSwitchToVariantEdit}>
              <EditOutlined /> AI Variants
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>





<div className="column ai-container">
    <div className="ai-choice-container">
        <div className="div-4" style={{ height: '350px' }}>
            <div className="ai-card-content">
                <div className="lottie-container">
                    <LottieAnimation
                        animationPath="https://lottie.host/f1888009-497f-4f39-957c-2dee57224074/cIuSvSj7bw.json"
                        width={400}
                        height={400}
                    />
                </div>
                <div className="ai-text-content">
                    <div className="ai-title-container">
                        <h2 className="ai-title">Online-store AI product descriptions text optimisation</h2>
                    </div>
                    <div className="ai-description">
                    This AI's core feature is boosting online store sales by optimizing product descriptions using AI. It automatically generates and A/B tests multiple versions of product descriptions, identifying the most compelling language to drive purchases. This results in significant sales increases of up to 40%. Its unique strength lies in its specialized Large Language Model trained specifically on high-converting e-commerce content, enabling it to craft product descriptions that outperform those generated by general AI model. 
                    </div>
                    <div className="div-42">
                        <img
                            loading="lazy"
                            src="https://cdn.builder.io/api/v1/image/assets/TEMP/d6ee974d01af2b851c664f3820c487ef8bcb9b56f1e93895d3fc10c00bca4c67?"
                            className="img"
                        />
                        <div className="div-43">
                            This model is ideal for online stores that need to fine-tune thousands of SKU descriptions
                        </div>
                    </div>
                    <div className="ai-card-actions">
                        <Switch checked={settings.ProductDescOptEnabled} onChange={(checked) => handleSettingChange('ProductDescOptEnabled', checked)} />
                        <div className="ai-setting-button"
                            onClick={() => showModal('Product Description Optimization', {url, aiName: 'Product Description Optimization'})}
                        >
                            <SettingOutlined /> Settings
                        </div>
                        <div className="ai-variant-button"
                            onClick={onSwitchToVariantEdit}
                        >
                            <EditOutlined /> AI Variants
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</div>


<div className="div-3">
    <div className="column ai-container">
        <div className="ai-choice-container">
            <div className="div-4" style={{ height: '350px' }}>
                <div className="ai-card-content">
                    <div className="lottie-container">
                        <LottieAnimation
                            animationPath="https://lottie.host/a0a3892d-0214-476f-9534-ca715b1a9f8a/uFmaS5zzFj.json"
                            width={400}
                            height={200}
                        />
                    </div>
                    <div className="ai-text-content">
                        <div className="ai-title-container">
                            <h2 className="ai-title">AI Basic translation (without AB testing)</h2>
                        </div>
                        <div className="ai-description">
                        Boost your sales with a multilingual website offering basic translations in 125 languages. This AI is perfect for sites with a small number of international visitors and no need for A/B testing of translations. Simply activate the model, and it will handle the rest. You can still use other website translators like Weglot or Google Translate, as this AI is designed to work alongside them without interference.
                        </div>
                        <div className="ai-card-actions">
                            <Switch checked={settings.AiBasicTranslationEnabled} onChange={(checked) => handleSettingChange('AiBasicTranslationEnabled', checked)} />
                            <div className="ai-setting-button"
                                onClick={() => showModal('Translation', {url, aiName: 'Advanced translation with A/B testing'})}
                            >
                                <SettingOutlined /> Settings
                            </div>
                            <div className="ai-variant-button"
                                onClick={onSwitchToVariantEdit}
                            >
                                <EditOutlined /> AI Variants
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div className="column ai-container">
        <div className="ai-choice-container">
            <div className="div-4" style={{ height: '350px' }}>
                <div className="ai-card-content">
                    <div className="lottie-container">
                        <LottieAnimation
                            animationPath="https://lottie.host/8eb52a8d-3298-466f-9b2b-c62f0e04e193/LaIwtdYRte.json"
                            width={350}
                            height={180}
                        />
                    </div>
                    <div className="ai-text-content">
                        <div className="ai-title-container">
                            <h2 className="ai-title">Advanced translation with A/B testing</h2>
                        </div>
                        <div className="ai-description">
                        This model core feature is boosting international sales by automatically translating websites into 125 languages. It uses AI-driven translation and A/B testing to optimize website text for each language, leading to a significant increase in sales, typically around +25%. Its key advantage lies in its ability to identify the highest-performing translation variants. By eliminating translation errors and dynamically adapting fonts for different languages, SEATEXT AI ensures a seamless user experience across the globe.
                        </div>
                        <div className="div-29">
                            <img
                                loading="lazy"
                                src="https://cdn.builder.io/api/v1/image/assets/TEMP/d6ee974d01af2b851c664f3820c487ef8bcb9b56f1e93895d3fc10c00bca4c67?"
                                className="img"
                            />
                            <div className="div-30">
                                A/B testing approach demonstrates an average increase in conversions by 25% plus eliminate any mistakes in translation
                            </div>
                        </div>
                        <div className="ai-card-actions">
                            <Switch checked={settings.AdvancedTranslationEnabled} onChange={(checked) => handleSettingChange('AdvancedTranslationEnabled', checked)} />
                            <div className="ai-setting-button"
                                onClick={() => showModal('Translation', {url, aiName: 'Advanced translation with A/B testing'})}
                            >
                                <SettingOutlined /> Settings
                            </div>
                            <div className="ai-variant-button"
                                onClick={onSwitchToVariantEdit}
                            >
                                <EditOutlined /> AI Variants
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div className="div-33">
    <div className="column ai-container">
        <div className="ai-choice-container">
            <div className="div-4" style={{ height: '350px' }}>
                <div className="ai-card-content">
                    <div className="lottie-container">
                        <LottieAnimation
                            animationPath="https://lottie.host/a57fc31f-6207-4375-859c-22347151cdab/Cd2HOPFeC3.json"
                            width={200}
                            height={200}
                        />
                    </div>
                    <div className="ai-text-content">
                        <div className="ai-title-container">
                            <h2 className="ai-title">AI for mobile adaptation</h2>
                        </div>
                        <div className="ai-description">
                        This AI feature helps increase mobile conversions by automatically adjusting website content for smaller screens. It uses AI to transform desktop content into shorter, more impactful versions tailored for mobile devices, ensuring that text is easy to read and engaging on any device. The process also includes automated A/B testing of different mobile content versions to find the one that performs best, further improving conversion rates.
                        </div>
                        <div className="div-42">
                            <img
                                loading="lazy"
                                src="https://cdn.builder.io/api/v1/image/assets/TEMP/d6ee974d01af2b851c664f3820c487ef8bcb9b56f1e93895d3fc10c00bca4c67?"
                                className="img"
                            />
                            <div className="div-43">
                                AI will optimize text for mobile devices, making it shorter and more impactful
                            </div>
                        </div>
                        <div className="ai-card-actions">
                            <Switch checked={settings.AiMobileAdaptationEnabled} onChange={(checked) => handleSettingChange('AiMobileAdaptationEnabled', checked)} />
                            <div className="ai-setting-button"
                                onClick={() => showModal('Mobile', {url, aiName: 'AI A/B testing for mobile adaptation'})}
                            >
                                <SettingOutlined /> Settings
                            </div>
                            <div className="ai-variant-button"
                                onClick={onSwitchToVariantEdit}
                            >
                                <EditOutlined /> AI Variants
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div className="column ai-container">
        <div className="ai-choice-container">
            <div className="div-4" style={{ height: '350px' }}>
                <div className="ai-card-content">
                    <div className="lottie-container">
                        <LottieAnimation
                            animationPath="https://lottie.host/1006dbdf-c308-47fb-b65c-60aca81ad2b2/u9QlVhfHpo.json"
                            width={200}
                            height={200}
                        />
                    </div>
                    <div className="ai-text-content">
                        <div className="ai-title-container">
                            <h2 className="ai-title">BETA model</h2>
                        </div>
                        <div className="ai-description">
                        This model is for clients in the experimental BETA program. It can not only rewrite your website in different styles, similar to the original model, but also completely change the text and communication flow on your site. Please avoid using this model if you require consistency in your website's content.
                        </div>
                        <div className="div-64">
                            <img
                                loading="lazy"
                                src="https://cdn.builder.io/api/v1/image/assets/TEMP/d6ee974d01af2b851c664f3820c487ef8bcb9b56f1e93895d3fc10c00bca4c67?"
                                className="img"
                            />
                            <div className="div-65">
                                AI without any limits or filters
                            </div>
                        </div>
                        <div className="ai-card-actions">
                            <Switch checked={settings.LandingPagesEnabled} onChange={(checked) => handleSettingChange('LandingpagesEnabled', checked)} />
                            <div className="ai-setting-button"
                                onClick={() => showModal('Landingpages', {url, aiName: 'Landingpages'})}
                            >
                                <SettingOutlined /> Settings
                            </div>
                            <div className="ai-variant-button"
                                onClick={onSwitchToVariantEdit}
                            >
                                <EditOutlined /> AI Variants
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</div>
</div>   
</div>   

</div>
</div>
<Modal
    title={`${currentModal.context.aiName} Settings`}
    visible={currentModal.visible}
    onCancel={closeModal}
    footer={null}
    width="66%"
    style={{
        position: 'fixed',
        top: 0,
        right: 0,
        bottom: 0,
        width: '66%',
        margin: 0,
        padding: 0,
    }}
    bodyStyle={{ 
        height: '100%', 
        overflowY: 'auto',
        width: '100%'
    }}
    wrapClassName="right-side-modal"
    maskStyle={{ backgroundColor: 'rgba(0, 0, 0, 0.45)' }}
>
    <div style={{ width: '100%', height: '100%', overflowY: 'auto' }}>
        {currentModal.type === 'Product Description Optimization' && <Etrade context={currentModal.context} />}
        {currentModal.type === 'AIABTesting' && <SettingsPage2 context={currentModal.context} />}
        {currentModal.type === 'Translation' && <TranslationSettingsPage context={currentModal.context} />}
        {currentModal.type === 'Mobile' && <Mobile context={currentModal.context} />}
        {currentModal.type === 'Landingpages' && <Landingpages context={currentModal.context} />}
    </div>
</Modal>
        </Card>
    );
};

export default URLDataCard;