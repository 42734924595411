import React, { useState, useEffect } from 'react';
import { Layout, Typography, Menu } from 'antd';
import { Link, Routes, Route, Navigate, useNavigate } from 'react-router-dom';
import Lottie from 'react-lottie';
import Cookies from 'js-cookie';
import {
  ApartmentOutlined, EditOutlined, ShopOutlined, AimOutlined, TranslationOutlined,
  UserOutlined, MobileOutlined, BulbOutlined, UploadOutlined, DownloadOutlined,
  AppstoreOutlined, BookOutlined, GlobalOutlined, ForkOutlined
} from '@ant-design/icons';

import "./welcome.css";
import { checkAndSaveDomainStatus, fetchLanguageCodesAndVariants } from './utils';
import Seatextdemo from './seatextdemo';
import AISalesPersonalisation from './AIsalespersonalisation';
import AIMarketingPersonalisation from './AIMarketingPersonalisation';
import TranslationSettingsPage from './Settingstranslation';
import VariantsEditPage from './VariantEdit';
import PlanSelectAndPayment from './PlanSelectAndPayment';
import YoutubeVideoPage from './YoutubeVideoPage';
import FAQPage from './FAQPage';
import ImageUploader from './upload';
import VariantNext from './variantnext';
import SettingsPage2 from './SettingsPage2';
import Etrade from './etrade';
import Mobile from './mobile';

const { Content, Sider } = Layout;
const { Text } = Typography;

const defaultOptions = {
  loop: true,
  autoplay: true, 
  animationData: null,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice'
  },
  path: 'https://lottie.host/529bf41e-e8d4-44dc-ad85-ac20b44fcd6a/jtT5t8jdkx.json'
};

export default function WelcomePage() {
  const [selectedKey, setSelectedKey] = useState('Seatextdemo');
  const [domainName, setDomainName] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [statsData, setStatsData] = useState({
    languages: 0,
    variants: 0
  });
  const navigate = useNavigate();
  useEffect(() => {
    checkAndSaveDomainStatus();
  
    const domainFromCookie = Cookies.get('domainName');
    const domainName = domainFromCookie || "seatext.com"; 
    setDomainName(domainName); 
  
    // Fetch data using the appropriate URL
    const fetchUrl = domainFromCookie ? `${domainFromCookie} Whole website` : "seatext.com";
    fetchLanguageCodesAndVariants(fetchUrl) 
        .then(data => {
          setStatsData({
            languages: data.languages.length,
            variants: data.variants || 0,
            domainLabel: fetchUrl // You can still use fetchUrl as domainLabel if needed
          });
        })
        .catch(error => {
          console.error('Failed to load language codes and variants:', error);
        });
  
    // Navigate if domain exists
    if (domainFromCookie) { 
      navigate("/welcome/aimanager"); 
    }
  
    setIsLoading(false);
  
    const intervalId = setInterval(checkDomainStatus, 60000);
    return () => clearInterval(intervalId);
  }, []);


  const checkDomainStatus = () => {
    checkAndSaveDomainStatus();
    const newDomain = Cookies.get('domainName');
    setDomainName(newDomain);
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  const menuItems = [
    {
      key: 'aimanager',
      icon: <ApartmentOutlined />,
      label: 'Main AI Hub',
      description: 'Assign specific AI models to different pages',
      link: '/welcome/aimanager'
    },
    {
      key: 'variantEdit',
      icon: <EditOutlined />,
      label: 'Review AI Performance & Adjust AI Text Variants',
      description: 'Edit AI variants in any language and see current status of testing',
      link: '/welcome/variant-edit'
    },
    {
      key: 'aiProduct',
      icon: <ShopOutlined />,
      label: 'AI for Online Stores',
      description: 'AI model refines product descriptions for maximum conversions',
      link: '/welcome/ai-product'
    },
    {
      key: 'aiImprovesConversion',
      icon: <AimOutlined />,
      label: 'General AI model improves any website sales',
      description: 'General AI model enhances conversion rates over time on any website',
      link: '/welcome/SettingsPage2'
    },
    {
      key: 'ai-translation',
      icon: <TranslationOutlined />,
      label: 'AI Website Translation',
      description: 'Automatically translates your website into 125 languages',
      link: '/welcome/TranslationSettingsPage'
    },
    {
      key: 'AIsales',
      icon: <UserOutlined />,
      label: 'AI personalize prospect experience',
      description: 'AI personalize prospect experience on your website',
      link: '/welcome/AIsales'
    },
    {
      key: 'aiMobile',
      icon: <MobileOutlined />,
      label: 'AI Mobile Optimization',
      description: 'AI ensures your mobile pages are concise and impactful',
      link: '/welcome/ai-mobile'
    },
    {
      key: 'AIMarketingPersonalisation',
      icon: <BulbOutlined />,
      label: 'AI personalize based on traffic source',
      description: 'AI personalizes landing pages based on traffic source and visitor data',
      link: '/welcome/AIMarketingPersonalisation'
    },
    {
      key: 'imageUploader',
      icon: <UploadOutlined />,
      label: 'Video and Image Uploader',
      description: 'Upload pictures to the translated version of the website',
      link: '/welcome/image-upload'
    },
    {
      key: 'seatext-install',
      icon: <DownloadOutlined />,
      label: 'How to Install SEATEXT',
      description: 'Step-by-step guide to install SEATEXT on your website',
      link: '/welcome/seatextdemo'
    },
    {
      key: 'plans-usage',
      icon: <AppstoreOutlined />,
      label: 'Subscription and Usage',
      description: 'Manage your subscription and monitor usage statistics',
      link: '/welcome/plan-selection'
    },
    {
      key: 'documentation',
      icon: <BookOutlined />,
      label: 'Documentation',
      description: 'Access comprehensive documentation and user guides',
      link: 'https://seatext.com/documentation'
    },
  ];

  return (
    <Layout style={{ minHeight: '100vh' }}>
      
      <Sider width="25%" className="custom-sider">
        <div className="logo-container">
          <div className="lottie-logo">
            <Lottie options={defaultOptions} />
          </div>
        </div>
        <div style={{ height: '20px' }}></div>
        <div className="client-info">
          <div className="domain-name" data-seatextonly>
            <GlobalOutlined />
            <span>{domainName ? domainName : 'DEMO account. Install on your website to start.'}</span>
          </div>
          <div className="stats-container">
          <div className="stat-item">
            
  <TranslationOutlined />
  <Text>
    Website translated into {statsData.languages} language
    {statsData.languages === 1 ? "" : "s"} 
  </Text>
</div>
            <div className="stat-item">
              <ForkOutlined />
              <Text>{statsData.variants} Variants in MVT Testing</Text>
            </div>
          </div>
        </div>
        <Menu
          theme="dark"
          mode="inline"
          selectedKeys={[selectedKey]}
          className="custom-menu"
          data-seatextdont
        >
          {menuItems.map(item => (
            <Menu.Item key={item.key} icon={item.icon}>
              <Link to={item.link}>
                <span className="menu-item-label">{item.label}</span>
                {item.description && (
                  <span className="menu-item-description">{item.description}</span>
                )}
              </Link>
            </Menu.Item>
          ))}
        </Menu>
      </Sider>
      <Layout className="site-layout">
        <Content className="site-content">
          <div className="content-container">
            <Routes>
              <Route path="/" element={<Navigate replace to="Seatextdemo" />} />
              <Route path="plan-selection" element={<PlanSelectAndPayment />} />
              <Route path="variant-edit" element={<VariantsEditPage/>} />
              <Route path="learn" element={<YoutubeVideoPage />} />
              <Route path="faq" element={<FAQPage />} />
              <Route path="/TranslationSettingsPage" element={<TranslationSettingsPage  />} />
              <Route path="/SettingsPage2" element={<SettingsPage2  />} />
              <Route path="aimanager" element={<VariantNext />} />
              <Route path="AIsales" element={<AISalesPersonalisation />} />
              <Route path="ai-mobile" element={<Mobile />} />
              <Route path="ai-product" element={<Etrade />} />
              <Route path="image-upload" element={<ImageUploader />} />
              <Route path="Seatextdemo" element={<Seatextdemo />} />
              <Route path="AIMarketingPersonalisation" element={<AIMarketingPersonalisation />} />
            </Routes>
          </div>
        </Content>
      </Layout>
    </Layout>
  );
}